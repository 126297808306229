.right_title[data-v-12d98a22] {
  width: 100%;
  margin: 0 auto;
  font-size: 13px;
}
.right_title h5[data-v-12d98a22] {
  font-weight: normal;
  font-size: 14px;
  padding-bottom: 10px;
}
.right_title p[data-v-12d98a22] {
  font-size: 12px;
  color: red;
  text-align: left !important;
}
.item_radio[data-v-12d98a22],
.one_item[data-v-12d98a22] {
  width: 100%;
  padding-left: 30px;
  height: 25px;
}
.item_radio2[data-v-12d98a22] {
  width: 100%;
  padding-left: 60px;
}
.item_lists[data-v-12d98a22] {
  width: 100%;
  padding-left: 60px;
}
.el-radio-group[data-v-12d98a22] {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
}
.none-border p[data-v-12d98a22] {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.orange[data-v-12d98a22] {
  color: orange !important;
}