.el-table .cell .el-radio-group[data-v-320dc42c] {
  white-space: pre-wrap !important;
  text-align: left;
}
[data-v-320dc42c] .el-radio {
  margin-right: 14px;
}
.text_initial[data-v-320dc42c] {
  white-space: pre-wrap;
  display: inline-block;
}