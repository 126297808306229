.content[data-v-3dee6640] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #b9b9b9;
  border-bottom: none;
  border-right: none;
}
.content tr[data-v-3dee6640] {
  padding: 0;
  margin: 0;
}
.content td[data-v-3dee6640] {
  border-right: 1px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9;
  line-height: 25px;
  padding: 5px 8px;
  color: #333;
  font-size: 13px;
}